<template>
    <div>
        <button v-if="false" @click="fetchChartData">Reload</button>
        <highcharts v-if="loaded" :options="chartOptions" ref="chart"/>
        <div v-else class="d-flex justify-content-center">
            <b-spinner :style="{'color':constants.spinnerColor}"></b-spinner>
        </div>
    </div>
</template>

<script>
    import {Chart} from 'highcharts-vue'
    import exportingInit from "highcharts/modules/exporting"
    import Highcharts from "highcharts"
    import dashboardChartMixin from '@/mixins/dashboardChartMixin'
    import string from "@/helpers/string";

    exportingInit(Highcharts)

    export default {
        name: 'CustomFieldFundingScatterChart',
        mixins: [dashboardChartMixin],
        components: {
            highcharts: Chart
        },
        data() {
            return {
                loaded: false,
                chartName: "StartupsByFundingAndCustomFieldValue",
                chartData: [],
                chartOptions: {
                    chart: {
                        type: "scatter",
                    },
                    title: {
                        text: "Startups By Funding"
                    },
                    xAxis: {
                        title: {
                            text: '<strong>Founded year</strong>'
                        },
                    },
                    yAxis: {
                        title: {
                            text: "Funding amount",
                        },
                        labels: {
                            formatter: function() {
                                return this.value ? string.formatNumberShort(this.value) : 'N/A';
                            }
                        }
                    },
                    plotOptions: {
                        series: {
                            marker : {
                                symbol: 'circle'
                            },
                            dataLabels: {
                                enabled: true,
                                formatter: function() {
                                    return  this.point.labelText;
                                },
                            }
                        }
                    },
                    tooltip: {
                        formatter: function () {
                            return this.point.label
                        }
                    },
                    series: [],
                },
            }
        },
        methods: {
            fetchChartData(initialCall = false) {
                this.getChartData(this.chartName).then(response => {
                    this.chartData = response.data
                    this.formatAndSetChartData()

                    if (!initialCall) {
                        this.$refs.chart.chart.hideLoading()
                    } else {
                        this.loaded = true
                    }
                })
            },

            formatAndSetChartData() {
                this.chartOptions.xAxis.categories = this.chartData.categories;
                this.chartOptions.series = [];

                for (let index in this.chartData.series) {
                    this.chartOptions.series.push({
                        ...this.chartData.series[index],
                        color: Highcharts.color(this.chartData.series[index].color).setOpacity(0.9).get(),
                        events: {
                            click: (e) => {
                                window.open('/startups/' + e.point.id, '_blank').focus();
                            }
                        }
                    })
                }
            },

            openStartupsModal(category) {
                this.$emit('showStartups', [this.chartName, `Startups in category "${category}"`, category])
            },
        }
    }
</script>
